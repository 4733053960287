import { makeAutoObservable } from 'mobx';
import { SimpleTeacherT } from 'types/shared/core/Teacher';

class SuccessAlertStoreOfTeacher {
  teacher: SimpleTeacherT | null = null;
  status: 'clockIn' | 'clockOut' = 'clockIn';
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  show = (teacher: SimpleTeacherT, status: 'clockIn' | 'clockOut') => {
    this.teacher = teacher;
    this.status = status;
    this.isOpen = true;

    setTimeout(() => {
      this.hide();
    }, 1000);
  };

  hide = () => {
    this.isOpen = false;
  };
}

export default SuccessAlertStoreOfTeacher;
