import { Stack, Typography } from '@mui/material';
import RoundImage from './RoundImage';
import iosStep1 from 'assets/images/iosGuide/ios-step-1.png';
import iosStep2 from 'assets/images/iosGuide/ios-step-2.png';
import iosStep3 from 'assets/images/iosGuide/ios-step-3.png';
import iosResult from 'assets/images/iosGuide/ios-result.png';
import GuideTypo from './GuideTypo';

function IosGuide() {
  return (
    <Stack
      spacing={4}
      sx={{
        width: '320px',
        margin: '0 auto',
      }}
    >
      <GuideTypo>
        1) 주소줄 하단 중앙의 화살표 모양 버튼을 눌러주세요.
        <RoundImage src={iosStep1} />
      </GuideTypo>

      <GuideTypo>
        2) {'"'}홈 화면에 추가{'"'} 버튼을 찾아 눌러주세요.
        <RoundImage src={iosStep2} />
      </GuideTypo>
      <GuideTypo>
        3) 이름 확인 후 우측 상단의 추가 버튼을 눌러 완료해주세요.
        <RoundImage src={iosStep3} />
      </GuideTypo>
      <GuideTypo>
        4) 이렇게 추가된 것을 확인할 수 있습니다.
        <RoundImage src={iosResult} />
      </GuideTypo>
    </Stack>
  );
}

export default IosGuide;
