import { Academy } from './Academy';
import { Bill } from './Bill';
import { Post } from './Post';
import { Attendance } from './Attendance';
import { Chat } from './Chat';
import { Homework } from './Homework';
import { Message } from './Message';

const Co = {
  Academy,
  Bill,
  Post,
  Attendance,
  Homework,
  Chat,
  Message,
};

export default Co;
