class Str {
  static hyphen(phoneNumber: string | null | undefined) {
    if (!phoneNumber) return '-';

    return phoneNumber
      .replace(/[^0-9]/, '')
      .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }

  static randomNumberStr(length: number): string {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static randomAlphaNumericStr(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static hasDigitsOnly(str) {
    return /^[0-9.,]+$/.test(str);
  }

  static moneyToHangeul(str: string | number) {
    let moneyStr: string = typeof str === 'string' ? str : str.toString();

    // console.log('moneyStr', moneyStr);
    let sign = '';

    if (moneyStr.startsWith('-')) {
      // 첫글자가 부호이면
      sign = '-';
      moneyStr = moneyStr.substring(1);
    }

    if (!Str.hasDigitsOnly(moneyStr)) return '';
    const hanA = [
      '',
      '일',
      '이',
      '삼',
      '사',
      '오',
      '육',
      '칠',
      '팔',
      '구',
      '십',
    ];
    const danA = [
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
    ];

    let result = '';

    for (let i = 0; i < moneyStr.length; i++) {
      let str = '';
      const han = hanA[moneyStr.charAt(moneyStr.length - (i + 1))];
      if (han !== '') str = han + danA[i];
      if (i === 4) str += '만';
      if (i === 8) str += '억';
      result = str + result;
    }
    // console.log(`money to hangeul ${sign + result}`);
    if ((sign + result).length > 0) return sign + result + '원';
    return '';
  }
}
export default Str;
