import { MyInfoT } from 'types/shared/core/User';
import { EmptyRequestT } from 'types/shared/reqAndRes/CommonRequest';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { api } from './api/api';
import { READ_MY_INFO } from 'network/shared/user';
import interfaceBridge from './interfaceBridge';

export const checkLoginState = async () => {
  console.log('checkLoginState');
  try {
    const response = await api<EmptyRequestT, MyResponse<MyInfoT>>(
      READ_MY_INFO,
      {},
    );

    console.log('MY_INFO response', response);

    if (response.isSuccess) {
      const academyName = response.object?.academyInfo?.name || '어나더 학원';
      interfaceBridge.setData('ACADEMY_NAME', academyName);
      self.location.href = '/attendance';
    } else {
      // 내 정보 읽어오지 못함
      self.location.href = '/signIn';
    }
  } catch (e: unknown) {
    // 오류
    console.log('error', e);

    if (e instanceof Error && e.message === 'Network Error') {
      // 인터넷 오류
      alert(`인터넷 연결을 확인하고 재실행 해주세요.`);
    } else {
      // error getting access token
      // 토큰 만료
      alert(`로그인 화면으로 이동합니다. `);
    }
    self.location.href = '/signIn';
  }
};
