import { Box, Typography } from '@mui/material';

type MiniChipPropsT = {
  text: string;
  bgColor: string;
  textColor: string;
};

function MiniChip({ text, textColor, bgColor }: MiniChipPropsT) {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        borderRadius: 2,
        px: 1,
        display: 'inline-block',
        height: '18px',
        lineHeight: '16px',
        verticalAlign: 'middle',
      }}
    >
      <Typography
        component="span"
        variant="subtitle1"
        color={textColor}
        fontSize="12px"
        fontWeight="bold"
        lineHeight="12px"
        sx={{ userSelect: 'none' }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default MiniChip;
