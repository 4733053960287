/* eslint-disable prefer-const */

// 이 파일은 웹뷰에서 네이티브에 요청하는 함수들의 시뮬레이션이다.

/*
  nav
  다른 화면으로 전환
  parameter
    target : 'current' | 'cover'
    url : string
    title : string // 도착하려는 페이지의 타이틀을 미리 설정 가능
    needResult : boolean
    identifier : string // needResult==true일 때 같이 들어옴

  fullscreen인지는 url의 queryString에 fullscreen=true를 보고 판단
  toolbar를 사용하지 않을 경우 withoutToolbar=true로 설정할 수 있음
  이 때, statusBar의 색은 statusBarColor=backgroundDefault 가 있으면 default, 아니면 backgroundColor 사용
  android에서는 cover이면 새로운 navigation component 시작됨


*/

import Base64 from 'utils/Base64';
import {
  saveToken,
  loadToken,
  setData,
  getData,
  getUserIds,
  deviceInfo,
} from './helper.storage';

console.log('pc browser interface loaded');

window.nativeFeatures = [
  // part 5 perference
  // token, account 관련
  saveToken,
  loadToken,
  getUserIds,
  // 데이터 저장
  setData,
  getData,
  deviceInfo,
];

const pcBrowserInterface = {
  features: window.nativeFeatures,

  requestNativeFeature: (jsonMessage) => {
    let ret;
    let actionHandled = false;
    const messageObj = JSON.parse(jsonMessage);
    pcBrowserInterface.features.forEach((feature) => {
      const { handled, returnValue } = feature(messageObj);
      if (handled) {
        actionHandled = true;
        ret = returnValue;
        return false;
      }
    });
    if (!actionHandled) {
      console.log('unhandled action : ', messageObj);
      throw new Error('unhandled action');
    }

    // 리턴값을 JSON.stringify 할지는 각 method가 결정한다.
    // 여기서는 base64 encode만
    // 즉, 즉시 return할 때의 결과는 json일수도 있고 일반 값일 수도 있다.
    // console.log('testInterface ret before encode', ret);
    return ret ? Base64.encode(ret) : '';
  },

  interfaceResultHandler: {},
};

export default pcBrowserInterface;
