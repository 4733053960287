import { Box, Container } from '@mui/material';
import { PropsWithChildren } from 'react';

type MyAreaPropsT = {
  width: 'full' | 'half';
};

function MyArea({ width, children }: PropsWithChildren<MyAreaPropsT>) {
  return (
    <Box
      sx={{
        width: width === 'full' ? '100%' : '50%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
}
export default MyArea;
