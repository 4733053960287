import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import interfaceBridge from 'utils/interfaceBridge';

// 뭔가 학생들의 리스트를 화면 중앙에 보여준다.

type MenuDialogPropsT = {
  open: boolean;
  onClose: () => void;
};

const logout = () => {
  interfaceBridge.goToSignIn();
};

const refresh = () => {
  self.location.href = '/attendance?dummy=' + Math.random();
};

export default function MenuDialog({ open, onClose }: MenuDialogPropsT) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">작업을 선택해 주세요</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <Button variant="outlined" fullWidth onClick={logout}>
            로그아웃
          </Button>
          <Button variant="outlined" fullWidth onClick={refresh}>
            새로고침
          </Button>
          <Button variant="text" fullWidth onClick={onClose}>
            닫기
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
