import { AttStatusOfTeacherT, AttStatusT } from 'types/shared/core/Attendance';
import MiniChip from './MiniChip';
import { useTheme } from '@mui/material';

function AttStatusChip({
  status,
}: {
  status: AttStatusT | AttStatusOfTeacherT;
}) {
  const { palette } = useTheme();

  if (status === 'present') {
    return (
      <MiniChip
        text="등원"
        textColor={palette.success.contrastText}
        bgColor={palette.success.light}
      />
    );
  } else if (status === 'left') {
    return (
      <MiniChip
        text="하원"
        textColor={palette.trivialAction.contrastText}
        bgColor={palette.trivialAction.main}
      />
    );
  }

  if (status === 'clockIn') {
    return (
      <MiniChip
        text="출근"
        textColor={palette.teacherClockInOut.contrastText}
        bgColor={palette.teacherClockInOut.light}
      />
    );
  } else if (status === 'clockOut') {
    return (
      <MiniChip
        text="퇴근"
        textColor={palette.teacherClockInOut.contrastText}
        bgColor={palette.teacherClockInOut.dark}
      />
    );
  }

  return null;
}
export default AttStatusChip;
