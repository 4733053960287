export const Bill = {
  billItemKinds: new Map([
    ['tution', '수강료'],
    ['material', '재료비'],
    ['meal', '급식비'],
    ['clothing', '피복비'],
    ['exam', '모의고사비'],
    ['textbook', '교재비'],
    ['etc', '기타'],
  ]),
  billPaymentMethodNames: new Map([
    ['onlineCard', '온라인카드결제'],
    ['wireTransfer', '송금확인'],
    ['offlineCard', '오프라인카드결제'],
    ['offlineCash', '오프라인현금수납'],
    ['accountBalance', '원비동장차감'],
    ['localCurrency', '지역화폐결제'],
    ['etcPaymentMethod', '기타방법결제'],

    ['onlineCardCancel', '온라인카드취소'],
    ['wireTransferRefund', '송금환불'],
    ['offlineCardCancel', '오프라인카드취소'],
    ['offlineCashRefund', '오프라인현금환불'],
    ['accountBalanceRefund', '원비통장환원'],
    ['localCurrencyCancel', '지역화폐취소'],
    ['etcPaymentMethodRefund', '기타방법환불'],

    ['memo', '메모'],
  ]),
  billPaymentMethodsBottom: [
    'offlineCard',
    'offlineCash',
    'wireTransfer',
    'localCurrency',
    'etcPaymentMethod',

    'offlineCardCancel',
    'offlineCashRefund',
    'wireTransferRefund',
    'localCurrencyCancel',
    'etcPaymentMethodRefund',
  ] as const,
  billCorpSettingTypes: new Map([
    ['epay', '어클페이'],
    ['cash', '현금전용'],
  ]),
  billItemTypes: new Map([
    ['normal', '일반항목'],
    ['discount', '할인항목'],
  ]),
  billCorpSettingStatusNames: new Map([
    ['registered', '등록됨'],
    ['activated', '사용가능'],
    ['suspended', '사용보류'],
    ['stopped', '사용중지'],
    ['deleted', '삭제됨'],
  ]),
  cashBillStatusNames: new Map([
    ['created', '생성됨'],
    ['errorOnIssuing', '발급오류'],
    ['issued', '발급됨'],
    ['successful', '국세청전송성공'],
    ['failed', '국세청전송실패'],
    ['canceled', '취소됨'],
  ]),
  cashBillKindNames: new Map([
    ['incomeTaxDeduction', '소득공제'],
    ['evidenceOfExpenditure', '지출증빙'],
  ]),

  billTemplateIssueStrategyNames: new Map([
    ['manual', '수동 발행'],
    ['autoIndividualByDay', '개인별 특정일 자동 발행'],
    // ['autoGroupByDay', '일괄 특정일 자동 발행'],
    // ['autoIndividualByCount', '개인별 특정회차후 자동 발행'],
  ]),
};
