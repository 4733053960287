import { makeAutoObservable } from 'mobx';
import { SimpleStudentT } from 'types/shared/core/Student';

class SuccessAlertStoreOfStudent {
  student: SimpleStudentT | null = null;
  status: 'present' | 'left' = 'present';
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  show = (student: SimpleStudentT, status: 'present' | 'left') => {
    this.student = student;
    this.status = status;
    this.isOpen = true;

    setTimeout(() => {
      this.hide();
    }, 1000);
  };

  hide = () => {
    this.isOpen = false;
  };
}

export default SuccessAlertStoreOfStudent;
