export const Post = {
  textColors: [
    'default',
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'purple',
    'gray',
  ] as const,
  bgColors: ['none', 'yellow', 'gray'] as const,
};
