/* eslint-disable @typescript-eslint/no-explicit-any */
// 이 파일은 asset 내의 interface.js 를
// typescript / react 에서 사용하기 좋게 감싸거나
// helper function 을 제공한다.

import { TokenObjT } from 'types/shared/core/Token';
import generalInterface from '../pcBrowserInterface/generalInterface';
import { NativeDataKeyT } from 'types/frontend/shared';
import { DeviceInfoKeyT } from 'types/frontend/attendance';

// base64

console.log('interfaceBridge loaded');

//
// main
function requestNativeFeature(
  obj: any,
  resultHandler?: any,
  shouldReturn?: boolean,
) {
  return generalInterface.requestNativeFeature(
    obj,
    resultHandler,
    shouldReturn,
  );
}
//
//
// token
// null 을 주면 삭제된다.
function saveToken(userId: number, tokenObj: TokenObjT | null): void {
  interfaceBridge.requestNativeFeature(
    {
      action: 'saveToken',
      userId,
      tokenObj,
    },
    null,
    true,
  );
}

function loadToken(userId: number): TokenObjT | null {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'loadToken',
      userId,
    },
    null,
    true,
  );

  if (!result) return null;

  return JSON.parse(result) as TokenObjT | null;
}

function setData(key: NativeDataKeyT | 'ACADEMY_NAME', dataStr: string): void {
  interfaceBridge.requestNativeFeature(
    {
      action: 'setData',
      key,
      valueStr: dataStr,
    },
    null,
    true,
  );
  // console.log('setData', dataStr);
}

function getData(key: NativeDataKeyT | 'ACADEMY_NAME'): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getData',
      key,
    },
    null,
    true,
  );
  // console.log('getData', result);
  return result as string;
}

function deviceInfo(key: DeviceInfoKeyT): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'deviceInfo',
      key,
    },
    null,
    true,
  );
  // console.log('deviceInfo', result);
  return result as string;
}

function getUserIds(): number[] {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getUserIds',
    },
    null,
    true, // should return
  ) as string;

  return JSON.parse(result) as number[];
}

// 로그아웃 역할도 수행
function goToSignIn() {
  // 기존 사용자 토큰 없애기
  const data = interfaceBridge.getData('CURRENT_USER_ID');
  const currentUserId = parseInt(data);
  console.log('goToSignIn currentUserId', currentUserId);
  if (currentUserId && currentUserId > 0) {
    interfaceBridge.saveToken(currentUserId, null);
  }

  console.log('nav 전');
  self.location.href = '/signIn';
}

const interfaceBridge = {
  requestNativeFeature,
  saveToken,
  loadToken,
  getUserIds,
  getData,
  setData,
  deviceInfo,
  goToSignIn,
};

export default interfaceBridge;

console.log('interfaceBridge loaded');
