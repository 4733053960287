import AttendanceStore from './AttendanceStore';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';

import AttForm from './Form/AttForm';
import AttLog from './Log/AttLog';
import { useEffect, useRef, useState } from 'react';

type AttendanceContainerPropsT = {
  store: AttendanceStore;
};

const AttendancContainer = observer(({ store }: AttendanceContainerPropsT) => {
  const myRef = useRef(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    // Ensure the browser supports ResizeObserver
    if ('ResizeObserver' in window) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect.height !== height) {
            setHeight(entry.contentRect.height);
          }
          if (
            entry.contentRect.width > 600 &&
            entry.contentRect.width > entry.contentRect.height
          ) {
            store.setIsWideTablet(true);
          } else {
            store.setIsWideTablet(false);
          }
        }
      });

      if (myRef.current) {
        observer.observe(myRef.current);
      }

      // Clean up observer on unmount
      return () => {
        if (myRef.current) {
          observer.unobserve(myRef.current);
        }
      };
    }
  }, []);

  return (
    <>
      <Box
        ref={myRef}
        component="main"
        sx={{
          position: 'fixed',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <AttForm attendanceStore={store} />
        <AttLog attendanceStore={store} />
      </Box>
    </>
  );
});

export default AttendancContainer;
