// 이 파일은 backend/api 에 원본으로 존재하고
// frontend 프로젝트에서 symbolic link로 공유한다.

const COMMON_ERROR = 0x00000100;

// const COMMON_WARNING = 0x00001000;

// 사용자 crud
const USER_ERROR = 0x00001100;

// 학생 교사 클래스에 관한 crud
const ACADEMY_ERROR = 0x00001200;

const FEEDS_ERROR = 0x00001300;

const BILL_ERROR = 0x00001400;

const POST_ERROR = 0x00001500;

const STUDENT_XLSX_ERROR = 0x00001600;

const CHAT_ERROR = 0x00001700;

const MESSAGE_ERROR = 0x00001800;

// 원에서 어나더쪽으로 요금제, 지불 관련
const ACADEMY_BILLING_AND_PAYMENT_ERROR = 0x00001900;

const HOMEWORK_XLSX_ERROR = 0x00002000;

const DUMMY_DATA_ERROR = 0x00002100;

// 원 설정 또는 원-사용자 설정에서 발생하는 오류들
const ACADEMY_SETTING_ERROR = 0x00002200;

//
// 개별 오류 묶음
//

// common
const COMMON_ERRORS = {
  COMMON_DATABASE_QUERY_ERROR: COMMON_ERROR | 0x01,
  COMMON_EXPECTED_DATA_NOT_FOUND: COMMON_ERROR | 0x02, // for select, 결과물 없음
  COMMON_NOTHING_TO_UPDATE: COMMON_ERROR | 0x03, // for update
  COMMON_INVALID_TOKEN: COMMON_ERROR | 0x04, // 토큰 검증, 갱신
  COMMON_EXPECTED_FIELD_IS_EMPTY: COMMON_ERROR | 0x05, // body/param/query 필요한거 없음, insert 시 data 부족
  COMMON_INSUFFICIENT_PERMISSION: COMMON_ERROR | 0x06, // 교사는 맞지만 권한이 모자람, 서비스 운영중 바뀔 수 있는 것은 403 대신 errorcode로 처리
  COMMMON_EXPECTED_ONE_MULTIPLE_DATA_EXISTS: COMMON_ERROR | 0x07,
  COMMMON_INVALID_REQUEST_VALUE: COMMON_ERROR | 0x08, // 형식은 지켜졌지만 특정한 상황에서 올수 없는 값인 경우
  COMMON_FILESERVER_ERROR: COMMON_ERROR | 0x09, // api서버가 fileserver와 요청 처리 중 오류 발생
  COMMON_DUPLICATE_ENTRY: COMMON_ERROR | 0x10, // unique해야 하는 field와 겹치는 데이터 이미 존재
  COMMON_UNEXPECTED_OBJECT_STATUS: COMMON_ERROR | 0x11, // 해당 요청을 수행하려는데 , 또는 결과물이 적절한 상태가 아님

  COMMON_TOO_MANY_REQUESTS: COMMON_ERROR | 0x12, // 너무 자주 요청이 들어올 때

  COMMON_FEATURE_NOT_ENABLED: COMMON_ERROR | 0x13, // 특정기능이 미사용중일 때

  COMMON_UNKNOWN_ERROR: COMMON_ERROR | 0xff,
};

// const COMMON_WARNINGS = {
//   COMMON_WARNING_UNCHANGED: COMMON_WARNING | 0x01,
// };

// user
const USER_ERRORS = {
  USER_PHONENUMBER_VERIFICATION_FAILED: USER_ERROR | 0x01,
  USER_PHONENUMBER_VERIFICATION_TOKEN_EXPIRED: USER_ERROR | 0x02,
  USER_SIGNUP_USER_ALREADY_EXISTS: USER_ERROR | 0x03, // email 겹쳤을 때
  USER_SIGNIN_INVALID_PASSWORD_OR_NOT_EXISTS: USER_ERROR | 0x10,

  USER_TOKEN_UNABLE_TO_CREATE_ACCESS_TOKEN: USER_ERROR | 0x11,

  USER_PHONENUMBER_VERIFICATION_TOKEN_INVALID: USER_ERROR | 0x12,
  USER_PHONENUMBER_ALREADY_USED: USER_ERROR | 0x13, // 학부모가 폰 변경시 다른 학부모가 해당 번호 이미 사용중
};

const ACADEMY_ERRORS = {
  // academy
  ACADEMY_DUPLICATE_STUDENT: ACADEMY_ERROR | 0x01, // 한 원 내에 동일 이름-학부모 전화번호
  ACADEMY_DUPLICATE_TEACHER: ACADEMY_ERROR | 0x02,
  ACADEMY_DUPLICATE_CLS: ACADEMY_ERROR | 0x03,
  ACADEMY_DUPLICATE_BOARD: ACADEMY_ERROR | 0x04,
  ACADEMY_NUMBER_OF_STUDENTS_EXCEEDS_LIMIT: ACADEMY_ERROR | 0x05, // 최대 학생수 초과
  ACADEMY_DUPLICATE_STUDENT_PHONE: ACADEMY_ERROR | 0x06, // 한 원 내에 학생 전화번호 중복
};

// feeds
const FEEDS_ERRORS = {
  FEEDS_INVALID_CURSOR: FEEDS_ERROR | 0x01,
  FEEDS_INVALID_SEARCH_ITEM: FEEDS_ERROR | 0x02,
};

// post
const POST_ERRORS = {
  POST_INVALID_POST_TOKEN: POST_ERROR | 0x01,
};

// student xlsx
const STUDENT_XLSX_ERRORS = {
  STUDENT_XLSX_INVALID_VERSION: STUDENT_XLSX_ERROR | 0x01,
  STUDENT_XLSX_INVALID_TITLE_ROW: STUDENT_XLSX_ERROR | 0x02,
  STUDENT_XLSX_NO_SHEET_FOUND: STUDENT_XLSX_ERROR | 0x03,

  STUDENT_XLSX_NO_NAME: STUDENT_XLSX_ERROR | 0x11,
  STUDENT_XLSX_NO_PARENT_PHONE: STUDENT_XLSX_ERROR | 0x12,
  STUDENT_XLSX_TOO_LONG_NAME: STUDENT_XLSX_ERROR | 0x13,
  STUDENT_XLSX_INVALID_NAME: STUDENT_XLSX_ERROR | 0x14,
  STUDENT_XLSX_INVALID_PARENT_PHONE: STUDENT_XLSX_ERROR | 0x15,
  STUDENT_XLSX_INVALID_STUDENT_PHONE: STUDENT_XLSX_ERROR | 0x16,
  STUDENT_XLSX_INVALID_ALT_PHONE: STUDENT_XLSX_ERROR | 0x17,
  STUDENT_XLSX_INVALID_BIRTHDAY: STUDENT_XLSX_ERROR | 0x18,
  STUDENT_XLSX_INVALID_GENDER: STUDENT_XLSX_ERROR | 0x19,
  STUDENT_XLSX_TOO_LONG_SCHOOL_NAME: STUDENT_XLSX_ERROR | 0x1a,
  STUDENT_XLSX_INVALID_GRADE: STUDENT_XLSX_ERROR | 0x1b,
  STUDENT_XLSX_INVALID_DATE_OF_ENROLLMENT: STUDENT_XLSX_ERROR | 0x1c,
  STUDENT_XLSX_TOO_LONG_SIMPLE_MEMO: STUDENT_XLSX_ERROR | 0x1d,
  STUDENT_XLSX_TOO_LONG_NICKNAME: STUDENT_XLSX_ERROR | 0x1e,
  STUDENT_XLSX_INVALID_NICKNAME: STUDENT_XLSX_ERROR | 0x1f,

  STUDENT_XLSX_EMPTY_ROW: STUDENT_XLSX_ERROR | 0x20,
};

// chat
const CHAT_ERRORS = {
  CHAT_ROOM_LIST_INVALID_CURSOR: CHAT_ERROR | 0x01,
  CHAT_ROOM_CONTENT_INVALID_CURSOR: CHAT_ERROR | 0x02,
};

// message
const MESSAGE_ERRORS = {
  MESSAGE_OUT_OF_QUOTA: MESSAGE_ERROR | 0x01,
  MESSAGE_LMS_DETERMINATION_CHANGED: MESSAGE_ERROR | 0x02,
  MESSAGE_RECEIVER_COUNT_CHANGED: MESSAGE_ERROR | 0x03,
  MESSAGE_CHARGE_DAILY_COUNT_EXCEEDS_LIMIT: MESSAGE_ERROR | 0x04,
};

// bill
const BILL_ERRORS = {
  // 자주쓰는 항목
  BILL_PRESET_ADD_EMPTY_ITEM: BILL_ERROR | 0x01,
  BILL_PRESET_ADD_DUPLICATE_ITEM_TITLE_IN_REQUEST: BILL_ERROR | 0x02, // 요청한 것 중 겹침
  BILL_PRESET_ADD_DUPLICATE_ITEM_TITLE: BILL_ERROR | 0x03, // 기존것과 겹침

  // 결제 처리
  BILL_PAYMENT_INVALID_AMOUNT: BILL_ERROR | 0x20, // 금액 부호 오류, 혹은 0원
  BILL_PAYMENT_ALREADY_CANCELED: BILL_ERROR | 0x21, // 이미 취소된 결제분을 취소하려 함
  BILL_PAYMENT_INVALID_AUTH_CODE: BILL_ERROR | 0x22, // 더미 승인 등 실제 승인이 없는 것을 취소 시도

  // 개별 청구서 관련
  BILL_NOT_FOUND_OR_INACTIVE: BILL_ERROR | 0x31, // 존재하지 않거나 inactive 상태의 bill에 뭔가를 기록하려함
  // BILL_CORP_SETTING_NOT_FOUND: BILL_ERROR | 0x32, // 사업자 정보 없음

  // 청구서 그룹 처리 관련
  BILL_GROUP_DELETE_NON_CANCELED_BILL_EXISTS: BILL_ERROR | 0x43,
  BILL_GROUP_EDIT_INVALID_BILL_GROUP_STATUS: BILL_ERROR | 0x44, // 발송한것 수정, 발송전을 발송후 수정

  // 현금영수증 관련
  BILL_CB_NO_POPBILL_ID: BILL_ERROR | 0x51, // 현금영수증 관련 - 팝빌아이디 설정 없음

  // 발행후 수정 관련
  BILL_EDIT_AFTER_SENDING_MAX_ADD_COUNT_MISMATCH: BILL_ERROR | 0x61,
  BILL_EDIT_AFTER_SENDING_ITEM_COUNT_MISMATCH: BILL_ERROR | 0x62,
  BILL_EDIT_AFTER_SENDING_BILL_ERROR: BILL_ERROR | 0x64,
  BILL_EDIT_AFTER_SENDING_AMOUNT_SUM_LESS_THAN_PAYMENT_SUM: BILL_ERROR | 0x64,
  BILL_EDIT_AFTER_SENDING_CANNOT_DELETE_WHILE_EDITING: BILL_ERROR | 0x65,
  BILL_EDIT_AFTER_SENDING_CANNOT_EDIT_CLOSED_BILL: BILL_ERROR | 0x66,
  BILL_EDIT_AFTER_SENDING_CANNOT_EDIT_CANCELED_BILL: BILL_ERROR | 0x67,
};

// billing
const ACADEMY_BILLING_AND_PAYMENT_ERRORS = {
  ACADEMY_BP_NO_BILLING_INFO: ACADEMY_BILLING_AND_PAYMENT_ERROR | 0x01,
  ACADEMY_BP_BILL_KEY_REGISTER_ERROR: ACADEMY_BILLING_AND_PAYMENT_ERROR | 0x02,
  ACADEMY_BP_TRANSACTION_APPROVE_ERROR:
    ACADEMY_BILLING_AND_PAYMENT_ERROR | 0x03,
};

// homework xlsx
const HOMEWORK_XLSX_ERRORS = {
  HOMEWORK_XLSX_INVALID_VERSION: HOMEWORK_XLSX_ERROR | 0x01,
  HOMEWORK_XLSX_INVALID_TITLE_ROW: HOMEWORK_XLSX_ERROR | 0x02,
  HOMEWORK_XLSX_NO_SHEET_FOUND: HOMEWORK_XLSX_ERROR | 0x03,
  HOMEWORK_XLSX_NUMBER_OF_PRESETS_EXCEEDS_LIMIT: HOMEWORK_XLSX_ERROR | 0x04,

  HOMEWORK_XLSX_NO_TITLE: HOMEWORK_XLSX_ERROR | 0x11,
  HOMEWORK_XLSX_TOO_LONG_TITLE: HOMEWORK_XLSX_ERROR | 0x12,
  // HOMEWORK_XLSX_INVALID_TITLE: HOMEWORK_XLSX_ERROR | 0x13, - 제목에는 딱히 제한되는 형식이 없음
  HOMEWORK_XLSX_DUPLICATED_TITLE: HOMEWORK_XLSX_ERROR | 0x14, // actionOnDuplicate = deny일 때 발생
  HOMEWORK_XLSX_NO_DESCRIPTION: HOMEWORK_XLSX_ERROR | 0x15,
  HOMEWORK_XLSX_TOO_LONG_DESCRIPTION: HOMEWORK_XLSX_ERROR | 0x16,

  HOMEWORK_XLSX_NO_ADDITIONAL_RESOURCE_NAME: HOMEWORK_XLSX_ERROR | 0x20,
  HOMEWORK_XLSX_TOO_LONG_ADDITIONAL_RESOURCE_NAME: HOMEWORK_XLSX_ERROR | 0x21,
  HOMEWORK_XLSX_NO_ADDITIONAL_RESOURCE_VALUE: HOMEWORK_XLSX_ERROR | 0x22,
  HOMEWORK_XLSX_TOO_LONG_ADDITIONAL_RESOURCE_VALUE: HOMEWORK_XLSX_ERROR | 0x23,

  HOMEWORK_XLSX_TOO_LONG_TAG_LIST: HOMEWORK_XLSX_ERROR | 0x24,
  HOMEWORK_XLSX_INVALID_TAG_LIST: HOMEWORK_XLSX_ERROR | 0x25,

  HOMEWORK_XLSX_EMPTY_ROW: HOMEWORK_XLSX_ERROR | 0x30,
};

const DUMMY_DATA_ERRORS = {
  DUMMY_DATA_ALREADY_EXISTS: DUMMY_DATA_ERROR | 0x01,
  DUMMY_DATA_NOT_EXISTS: DUMMY_DATA_ERROR | 0x02,
  DUMMY_DATA_PC_VISIT_REQUIRED: DUMMY_DATA_ERROR | 0x03,
};

const ACADEMY_SETTING_ERRORS = {
  ACADEMY_SETTING_NO_DESK_CHAT_SUBSCRIBER: ACADEMY_SETTING_ERROR | 0x01,
};

export const ErrorCode = {
  ...COMMON_ERRORS,
  // ...COMMON_WARNINGS,
  ...USER_ERRORS,
  ...ACADEMY_ERRORS,
  ...FEEDS_ERRORS,
  ...BILL_ERRORS,
  ...POST_ERRORS,
  ...STUDENT_XLSX_ERRORS,
  ...CHAT_ERRORS,
  ...MESSAGE_ERRORS,
  ...ACADEMY_BILLING_AND_PAYMENT_ERRORS,
  ...HOMEWORK_XLSX_ERRORS,
  ...DUMMY_DATA_ERRORS,
  ...ACADEMY_SETTING_ERRORS,
};
