import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import SignInStore from './SignInStore';

import { initialFormValue, validationSchema } from './formData';
import {
  Stack,
  TextField,
  Button,
  Box,
  Card,
  Typography,
  styled,
} from '@mui/material';

import logoImageDark from 'assets/images/start/logo_dark.png';
import { Formik } from 'formik';
import MyAlertDialog from 'Alert/MyAlertDialog';

type SignInContainerPropsT = {
  store: SignInStore;
};

const AnotherClassLogoImage = styled('img')(
  `
    width: 250px;
    height: auto;
  `,
);

const SignInContainer = observer(({ store }: SignInContainerPropsT) => {
  const { init, submit, myAlertStore } = store;

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          width: '100%',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
          // position: 'fixed',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            mb: 4,
          }}
        >
          <AnotherClassLogoImage src={logoImageDark} />
        </Box>
        <Card elevation={16} sx={{ m: 4, mx: 'auto', maxWidth: 'md', p: 4 }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h4">Log in</Typography>
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              출결 전용 원 id와 비번을 사용해주세요. <br />
              어클앱-더보기-원설정-출결설정에서 확인 가능합니다.
            </Typography>
          </Box>

          <Formik
            initialValues={initialFormValue}
            validationSchema={validationSchema}
            onSubmit={submit}
            enableReinitialize
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2} mt={2}>
                  <TextField
                    fullWidth
                    label="출결 전용 원 id (숫자)"
                    name="academyId"
                    type="tel"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.academyId}
                    error={!!(touched.academyId && errors.academyId)}
                    helperText={touched.academyId && errors.academyId}
                  />
                  <TextField
                    fullWidth
                    label="비밀번호 (숫자)"
                    name="password"
                    type="tel"
                    required
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={!!(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />

                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    로그인
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
      <MyAlertDialog store={myAlertStore} />
    </>
  );
});
export default SignInContainer;
