import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

function GuideTypo({ children }: PropsWithChildren) {
  return (
    <Typography variant="body1" color="white">
      {children}
    </Typography>
  );
}
export default GuideTypo;
