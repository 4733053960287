// 이 소스 수정시 public/assets/js/config 도 같이 수정해야 한다.
// node env 등 환경설정 내용을 구조화 해 내보낸다.

const Config = {
  apiBaseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  maxLogCount: 100,
};

console.log('process.env', process.env);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).anotherClassConfig = Config;

export default Config;
