import { Box, Button, Divider, Typography, useTheme } from '@mui/material';

type LogHeaderPropsT = {
  onBtnClick?: () => void;
};

function LogHeader({ onBtnClick }: LogHeaderPropsT) {
  const { palette } = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          height: '44px',
          flex: '0 0 44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h5"
          color="white"
          align="center"
          sx={{ userSelect: 'none' }}
        >
          최근 기록
        </Typography>
        {onBtnClick && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Button variant="text" onClick={onBtnClick}>
              닫기
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
}

export default LogHeader;
