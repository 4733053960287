import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

type AvatarSrcTypeT = string | React.ElementType | null;

type PersonItemProps = {
  variant: 'list' | 'grid';
  avatarSrc: AvatarSrcTypeT;
  name: string;
  descText: string | null;
  extraText: string | null;
  selectable?: boolean;
  selected?: boolean;
  smallAvatar?: boolean;
  onClick: () => void;
};

const isElementType = (value: AvatarSrcTypeT): value is React.ElementType => {
  return typeof value === 'object';
};

function PersonItem({
  variant,
  avatarSrc,
  name,
  descText,
  extraText,
  selectable = false,
  selected = false,
  smallAvatar = false,
  onClick,
}: PersonItemProps) {
  const { palette } = useTheme();
  const IconComponent = isElementType(avatarSrc) ? avatarSrc : null;

  if (variant === 'list') {
    return (
      <Box
        onClick={onClick}
        sx={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <Avatar
          src={typeof avatarSrc === 'string' ? avatarSrc : undefined}
          sx={{
            height: smallAvatar ? 20 : 42,
            width: smallAvatar ? 20 : 42,
          }}
        >
          {IconComponent && <IconComponent />}
        </Avatar>
        <Box sx={{ ml: 1, flexGrow: 1, minWidth: 0 }}>
          <Typography color="textSecondary" variant="body2">
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              display="inline"
              fontSize="1rem"
              fontWeight="bold"
              marginRight={1}
            >
              {name}
            </Typography>

            {descText}
          </Typography>
          <Typography color="textSecondary" variant="body2" noWrap>
            {extraText}
          </Typography>
        </Box>
        {selectable && selected && (
          <IconButton
            color="primary"
            aria-label=""
            sx={{
              marginRight: '0px',
            }}
          >
            <CheckCircleIcon />
          </IconButton>
        )}
      </Box>
    );
  } else {
    // (variant === 'grid')
    const borderColor: string =
      selectable && selected ? palette.primary.main : 'rgba(0,0,0,0)';

    return (
      <Grid
        item
        onClick={onClick}
        xs={4}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: 60,
            height: 60,

            borderRadius: '30px',
            border: `1.5px solid ${borderColor}`,
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            marginBottom: 1,
          }}
        >
          <Avatar
            src={typeof avatarSrc === 'string' ? avatarSrc : undefined}
            sx={{
              height: 50,
              width: 50,
            }}
          >
            {IconComponent && <IconComponent />}
          </Avatar>
        </Box>

        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
          display="inline"
          fontSize="1rem"
          fontWeight="bold"
          lineHeight="1.2"
        >
          {name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
          lineHeight="1.2"
          noWrap
          maxWidth="100%"
        >
          {descText}
        </Typography>

        {/* {selectable && selected && (
         
        )} */}
      </Grid>
    );
  }
}
export default React.memo(PersonItem);
