import { makeAutoObservable } from 'mobx';
import { api } from 'utils/api/api';
import {
  AttRecordCreateByCodeRequestT,
  AttRecordOfTeacherCreateByCodeRequestT,
} from 'types/shared/reqAndRes/Attendance';
import { SimpleStudentT } from 'types/shared/core/Student';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import {
  CREATE_ATT_RECORD_FROM_DEVICE,
  CREATE_ATT_RECORD_OF_TEACHER_FROM_DEVICE,
} from 'network/shared/attendance';
import { ErrorCode } from 'constants/shared/ErrorCode';
import MyAlertStore from 'Alert/MyAlertStore';
import SuccessAlertStoreOfStudent from './Form/SuccessAlertStoreOfStudent';
import SoundStore from './Form/SoundStore';
import Config from 'config/Config';
import { SimpleTeacherT } from 'types/shared/core/Teacher';
import SuccessAlertStoreOfTeacher from './Form/SuccessAlertStoreOfTeacher';

const possibleKeys = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '*',
  '#',
  'Backspace',
  'Enter', // 등원
  'Add', // 하원
  '=', // 하원
];

export type AttLogT = {
  name: string;
  isTeacher: boolean;
  status: 'present' | 'left' | 'clockIn' | 'clockOut';
  createdAt: Date;
};

export default class AttendanceStore {
  myAlertStore = new MyAlertStore();
  successAlertStoreOfStudent = new SuccessAlertStoreOfStudent();
  successAlertStoreOfTeacher = new SuccessAlertStoreOfTeacher();

  soundStore = new SoundStore();

  isWideTablet = false;
  isTeacherAtt = false;

  viewMode: 'form' | 'log' = 'form';

  chars: string[] = [];
  canTypeMore = true;

  possibleTargetStudentList: SimpleStudentT[] = [];
  possibleTargetTeacherList: SimpleTeacherT[] = [];

  dialogOpen = false;
  isStatusBtnEnabled = false;

  lastClickedStatus: 'present' | 'left' | 'clockIn' | 'clockOut' | null = null;

  logs: AttLogT[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsWideTablet = (value: boolean) => {
    this.isWideTablet = value;
    console.log('isWideTablet', this.isWideTablet);
  };

  handleToggleViewMode = () => {
    this.viewMode = this.viewMode === 'form' ? 'log' : 'form';
  };

  handleStudentClick = (student: SimpleStudentT) => {
    this.dialogOpen = false;
    if (
      this.lastClickedStatus !== 'present' &&
      this.lastClickedStatus !== 'left'
    ) {
      return;
    }

    this.createAttRecordFromDevice(this.lastClickedStatus, student);
  };

  handleTeacherClick = (teacher: SimpleTeacherT) => {
    this.dialogOpen = false;
    if (
      this.lastClickedStatus !== 'clockIn' &&
      this.lastClickedStatus !== 'clockOut'
    ) {
      return;
    }

    this.createAttRecordOfTeacherFromDevice(this.lastClickedStatus, teacher);
  };

  handleDialogClose = () => {
    this.dialogOpen = false;
  };

  handleStatusBtnClick = (status: 'in' | 'out') => {
    this.soundStore.playSound('touch');

    if (this.isTeacherAtt) {
      // 교사
      this.createAttRecordOfTeacherFromDevice(
        status === 'in' ? 'clockIn' : 'clockOut',
      );
    } else {
      // 학생
      this.createAttRecordFromDevice(status === 'in' ? 'present' : 'left');
    }
  };

  handleBackPressed = () => {
    this.deleteNumberField();
    this.soundStore.playSound('delete');
  };

  deleteNumberField = () => {
    this.chars = [];
    this.updateButtonStatus();
  };

  handleKeyDown = (key: string) => {
    if (!possibleKeys.includes(key)) return;
    console.log(key);

    if (key === 'Backspace') {
      this.handleBackPressed();
    } else if (key === 'Enter') {
      if (this.isStatusBtnEnabled) {
        this.handleStatusBtnClick('in');
      }
    } else if (key === 'Add' || key === '=') {
      if (this.isStatusBtnEnabled) {
        this.handleStatusBtnClick('out');
      }
    } else {
      this.handleNumberPadClick(key);
    }
  };

  handleNumberPadClick = (char: string) => {
    if (!this.canTypeMore) return;
    this.chars.push(char);
    this.soundStore.playSound('touch');
    this.updateButtonStatus();
  };

  updateButtonStatus = () => {
    // 교사 모드인지
    this.isTeacherAtt = this.chars.length > 0 && this.chars[0] === '#';

    if (this.isInputDone()) {
      this.canTypeMore = false;
      this.isStatusBtnEnabled = true;
    } else {
      this.canTypeMore = true;
      this.isStatusBtnEnabled = false;
    }
  };

  createAttRecordFromDevice = async (
    status: 'present' | 'left',
    student?: SimpleStudentT,
  ) => {
    console.log('createAttRecordFromDevice start');

    const attCode = this.chars.join('');

    try {
      const result = await api<
        AttRecordCreateByCodeRequestT,
        MyResponse<SimpleStudentT>
      >(CREATE_ATT_RECORD_FROM_DEVICE(0), {
        academyId: 0,
        attCode,
        status,
        studentId: student?.id,
      });

      console.log('createAttRecordFromDevice end');
      console.log('result', result);

      if (result.isSuccess && result.objects) {
        // objects 결과값이 1개인 것과 여러개인 것 의미 다름
        if (result.objects.length === 1) {
          this.handleStudentSuccess(result.objects[0], status);
        } else {
          this.handleAmbiguousCodeForStudent(result.objects, status);
        }
      } else {
        if (result.errorCode === ErrorCode.COMMON_EXPECTED_DATA_NOT_FOUND) {
          this.handleNoStudent();
        }
      }
    } catch (e) {
      console.log(e);
      await this.myAlertStore.customAlert(
        '오류',
        `문제가 발생했습니다. 문제가 계속되면 메뉴버튼을 눌러 로그아웃 후 다시 로그인 부탁드립니다.`,
        '닫기',
      );
    }
  };

  createAttRecordOfTeacherFromDevice = async (
    status: 'clockIn' | 'clockOut',
    teacher?: SimpleTeacherT,
  ) => {
    console.log('createAttRecordFromDevice start');

    const attCode = this.chars.join('');

    try {
      const result = await api<
        AttRecordOfTeacherCreateByCodeRequestT,
        MyResponse<SimpleTeacherT>
      >(CREATE_ATT_RECORD_OF_TEACHER_FROM_DEVICE(0), {
        academyId: 0,
        attCode,
        status,
        teacherId: teacher?.teacherId,
      });

      console.log('createAttRecordFromDevice end');
      console.log('result', result);

      if (result.isSuccess && result.objects) {
        // objects 결과값이 1개인 것과 여러개인 것 의미 다름
        if (result.objects.length === 1) {
          this.handleTeacherSuccess(result.objects[0], status);
        } else {
          this.handleAmbiguousCodeForTeacher(result.objects, status);
        }
      } else {
        if (result.errorCode === ErrorCode.COMMON_EXPECTED_DATA_NOT_FOUND) {
          this.handleNoTeacher();
        }
      }
    } catch (e) {
      console.log(e);
      await this.myAlertStore.customAlert(
        '오류',
        `문제가 발생했습니다. 문제가 계속되면 메뉴버튼을 눌러 로그아웃 후 다시 로그인 부탁드립니다.`,
        '닫기',
      );
    }
  };

  handleStudentSuccess = async (
    student: SimpleStudentT,
    status: 'present' | 'left',
  ) => {
    console.log(student);
    console.log('handleSuccess', status);
    this.successAlertStoreOfStudent.show(student, status);
    this.soundStore.playSound('success');
    this.deleteNumberField();
    this.addLogOfStudent(student, status);
  };

  handleTeacherSuccess = async (
    teacher: SimpleTeacherT,
    status: 'clockIn' | 'clockOut',
  ) => {
    console.log(teacher);
    console.log('handleSuccess', status);
    this.successAlertStoreOfTeacher.show(teacher, status);
    this.soundStore.playSound('success');
    this.deleteNumberField();
    this.addLogOfTeacher(teacher, status);
  };

  addLogOfStudent = async (
    student: SimpleStudentT,
    status: 'present' | 'left',
  ) => {
    const date = new Date();
    const maskedName =
      student.name.length < 3
        ? student.name.substring(0, 1) + '*'
        : student.name.substring(0, 1) +
          '*' +
          student.name.substring(student.name.length - 1);

    const log = {
      name: maskedName,
      isTeacher: false,
      status,
      createdAt: date,
    };

    this.logs.unshift(log);
    if (this.logs.length > Config.maxLogCount) this.logs.pop();
  };

  addLogOfTeacher = async (
    teacher: SimpleTeacherT,
    status: 'clockIn' | 'clockOut',
  ) => {
    const date = new Date();
    const maskedName =
      teacher.nickname.length < 3
        ? teacher.nickname.substring(0, 1) + '*'
        : teacher.nickname.substring(0, 1) +
          '*' +
          teacher.nickname.substring(teacher.nickname.length - 1);

    const log = {
      name: maskedName,
      isTeacher: true,
      status,
      createdAt: date,
    };

    this.logs.unshift(log);
    if (this.logs.length > Config.maxLogCount) this.logs.pop();
  };

  handleAmbiguousCodeForStudent = (
    studentList: SimpleStudentT[],
    status: 'present' | 'left',
  ) => {
    this.possibleTargetStudentList = studentList;
    this.possibleTargetTeacherList = [];
    this.lastClickedStatus = status;
    this.dialogOpen = true;
  };

  handleAmbiguousCodeForTeacher = (
    teacherList: SimpleTeacherT[],
    status: 'clockIn' | 'clockOut',
  ) => {
    this.possibleTargetTeacherList = teacherList;
    this.possibleTargetStudentList = [];
    this.lastClickedStatus = status;
    this.dialogOpen = true;
  };

  handleNoStudent = async () => {
    this.soundStore.playSound('error');
    await this.myAlertStore.customAlert(
      '오류',
      `코드에 해당하는 학생이 없습니다.`,
      '닫기',
    );
    this.deleteNumberField();
  };

  handleNoTeacher = async () => {
    this.soundStore.playSound('error');
    await this.myAlertStore.customAlert(
      '오류',
      `코드에 해당하는 교사가 없습니다.`,
      '닫기',
    );
    this.deleteNumberField();
  };

  isInputDone = (): boolean => {
    if (!this.chars.length) return false;
    if (this.chars[0] === '*') {
      return this.chars.length >= 5;
    }
    if (this.chars[0] === '#') {
      return this.chars.length >= 5;
    }

    return this.chars.length >= 4;
  };

  welcome = async () => {
    await this.myAlertStore.customAlert(
      '어나더클래스 출결앱',
      `환영합니다. 코드를 입력해 등원/하원을 기록해주세요.`,
      '닫기',
    );
    this.deleteNumberField();
  };
}
