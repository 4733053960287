import axios from 'axios';

import Token from 'utils/Token';
import interfaceBridge from './interfaceBridge';
import Config from 'config/Config';
import { RefreshAccessTokenRequestT } from 'types/shared/reqAndRes/Token';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { TokenObjT } from 'types/shared/core/Token';
import { DO_REFRESH_ACCESS_TOKEN } from 'network/shared/user';

type AccessTokenStateT = 'NORMAL' | 'REFRESHING' | 'ERROR';

class AccessTokenManager {
  accessTokenState: AccessTokenStateT = 'NORMAL';
  count = 0;

  // accessToken 이 갱신되면 실행할 콜백의 집합
  observers: ((at: string) => void)[] = [];

  refreshAccessToken = async (): Promise<string> => {
    this.accessTokenState = 'REFRESHING';
    this.count++;
    console.log('3');
    const refreshToken = Token.getToken('refreshToken');
    if (!refreshToken) {
      this.accessTokenState = 'ERROR';
      return '';
    }

    const deviceName = interfaceBridge.deviceInfo('deviceName');

    // console.log(
    //   'AccessTokenManager accessToken을 다시 받는 중입니다.',
    //   this.count,
    // );
    // console.log('AccessTokenManager refreshToken', refreshToken);
    // console.log(
    //   'AccessTokenManager url',
    //   Config.apiBaseURL + DO_REFRESH_ACCESS_TOKEN.path,
    // );

    const refreshAccessTokenRequest: RefreshAccessTokenRequestT = {
      refreshToken,
      deviceName,
    };
    try {
      const result = await axios.post<MyResponse<TokenObjT>>(
        `${Config.apiBaseUrl}${DO_REFRESH_ACCESS_TOKEN.path}`,
        refreshAccessTokenRequest,
      );

      let newAccessToken = '';
      if (
        result.status === 200 &&
        result.data.isSuccess &&
        result.data.object?.accessToken
      ) {
        console.log(
          'AccessTokenManager 새 accessToken을 발급받았습니다.',
          result.data.object.accessToken,
        );

        newAccessToken = result.data.object.accessToken;

        interfaceBridge.saveToken(
          result.data.object.userId,
          result.data.object,
        );
      }

      if (!newAccessToken) {
        this.accessTokenState = 'ERROR';
        throw new Error('error getting access token');
      }

      // 쌓여있는 callback도 수행 필요
      this.accessTokenState = 'NORMAL';
      this.observers.forEach((cb) => cb(newAccessToken));
      this.observers.length = 0;
      return newAccessToken;
      //
    } catch (e: unknown) {
      console.log('AccessTokenManager refresh token error : ', e);
      this.accessTokenState = 'ERROR';
      throw e;
    }
  }; // end of refreshAccessToken

  getNewAccessToken = (): Promise<string> => {
    if (this.accessTokenState === 'REFRESHING') {
      // 남이 요청해 두었음, 결과값만 받으면 됨
      console.log(
        'AccessTokenManager 남이 요청해 두었음, 결과값만 받으면 됨',
        this.count,
      );

      return new Promise((resolve) => {
        this.observers.push((at) => {
          console.log('AccessTokenManager 남이 요청한거 결과 나옴', at);
          resolve(at);
        });
      });
    }
    console.log('2');
    return this.refreshAccessToken();
  };
}

export default new AccessTokenManager();
