import {
  Direction,
  PaletteColor,
  PaletteColorOptions,
  Theme,
} from '@mui/material';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

import { baseThemeOptions } from './baseThemeOptions';
import { darkThemeOptions } from './darkThemeOptions';
import { lightThemeOptions } from './lightThemeOptions';

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral?: Neutral;
    other: PaletteColorOptions;
    trivialAction: PaletteColor;
    inactiveStatus: PaletteColor;
    teacherClockInOut: PaletteColor;
  }

  interface PaletteOptions {
    neutral?: Neutral;
    other: PaletteColorOptions;
    trivialAction: PaletteColor;
    inactiveStatus: PaletteColor;
    teacherClockInOut: PaletteColor;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    trivialAction: true;
  }
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tte?: any;
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    // darkThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction,
    },
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
