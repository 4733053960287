import { Stack, Typography } from '@mui/material';
import RoundImage from './RoundImage';
import androidStep1 from 'assets/images/androidGuide/and-step-1.png';
import androidStep2 from 'assets/images/androidGuide/and-step-2.png';
import androidStep3 from 'assets/images/androidGuide/and-step-3.png';
import androidStep4 from 'assets/images/androidGuide/and-step-4.png';
import androidResult from 'assets/images/androidGuide/and-result.png';
import GuideTypo from './GuideTypo';

function AndroidGuide() {
  return (
    <Stack
      spacing={4}
      sx={{
        width: '320px',
        margin: '0 auto',
      }}
    >
      <GuideTypo>
        1) 주소줄 하단 = 메뉴 버튼을 눌러주세요.
        <RoundImage src={androidStep1} />
      </GuideTypo>

      <GuideTypo>
        2) {'"'}현재 페이지 추가{'"'} 버튼을 찾아 눌러주세요.
        <RoundImage src={androidStep2} />
      </GuideTypo>
      <GuideTypo>
        3) {'"'}홈 화면{'"'} 버튼을 찾아 눌러주세요.
        <RoundImage src={androidStep3} />
      </GuideTypo>
      <GuideTypo>
        4) 이름 확인 후 추가 버튼을 눌러 완료해주세요.
        <RoundImage src={androidStep4} />
      </GuideTypo>
      <GuideTypo>
        5) 이렇게 추가된 것을 확인할 수 있습니다.
        <RoundImage src={androidResult} />
      </GuideTypo>
    </Stack>
  );
}

export default AndroidGuide;
