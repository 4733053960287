import { Button, Divider, Stack } from '@mui/material';
import NumberField from './NumberField';
import NumberPad from './NumberPad';
import MyArea from '../MyArea';
import FormHeader from './FormHeader';
import AttendanceStore from '../AttendanceStore';
import { observer } from 'mobx-react-lite';
import StudentsDialog from './PersonDialog';
import { useEffect, useState } from 'react';
import MyAlertDialog from 'Alert/MyAlertDialog';
import SuccessAlertDialogOfStudent from './SuccessAlertDialogOfStudent';
import MenuDialog from './MenuDialog';
import SuccessAlertDialogOfTeacher from './SuccessAlertDialogOfTeacher';

type AttFormPropsT = {
  attendanceStore: AttendanceStore;
};

const AttForm = observer(({ attendanceStore }: AttFormPropsT) => {
  const {
    possibleTargetStudentList,
    possibleTargetTeacherList,
    dialogOpen,
    handleStudentClick,
    handleTeacherClick,
    handleNumberPadClick,
    handleDialogClose,
    handleStatusBtnClick,
    isStatusBtnEnabled,
    canTypeMore,
    handleKeyDown,
    handleBackPressed,
    myAlertStore,
    successAlertStoreOfStudent,
    successAlertStoreOfTeacher,
    welcome,
    isWideTablet,
    isTeacherAtt,
  } = attendanceStore;

  useEffect(() => {
    const tempHandleKeyDown = (event: KeyboardEvent) => {
      handleKeyDown(event.key);
    };
    document.addEventListener('keydown', tempHandleKeyDown);

    return () => {
      document.removeEventListener('keydown', tempHandleKeyDown);
    };
  }, []);

  useEffect(() => {
    welcome(); // 사용자와 최초 인터랙션 위함, 안하면 오디오 플레이 안됨
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  if (!attendanceStore.isWideTablet && attendanceStore.viewMode !== 'form')
    return null;

  return (
    <>
      <MyArea width={attendanceStore.isWideTablet ? 'half' : 'full'}>
        <FormHeader
          onLeftBtnClick={() => setMenuOpen(true)}
          onRightBtnClick={
            attendanceStore.isWideTablet
              ? undefined
              : attendanceStore.handleToggleViewMode
          }
        />
        <Stack
          spacing={3}
          sx={{
            flex: 1,
            pt: '24px',
            pb: '24px',
          }}
        >
          <NumberField
            chars={attendanceStore.chars}
            onBackPressed={handleBackPressed}
          />
          <NumberPad
            isRound={!isWideTablet}
            canClick={canTypeMore}
            onClick={handleNumberPadClick}
          />
        </Stack>
        <Divider />
        <Stack
          direction="row"
          p={3}
          spacing={3}
          sx={{
            flex: '0 0 40px',
          }}
        >
          {isTeacherAtt ? (
            <>
              <Button
                variant="contained"
                disabled={!isStatusBtnEnabled}
                sx={{ flex: 1, fontSize: '24px', whiteSpace: 'nowrap' }}
                onClick={() => handleStatusBtnClick('in')}
              >
                출근
              </Button>
              <Button
                variant="contained"
                disabled={!isStatusBtnEnabled}
                sx={{ flex: 1, fontSize: '24px', whiteSpace: 'nowrap' }}
                onClick={() => handleStatusBtnClick('out')}
              >
                퇴근
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                disabled={!isStatusBtnEnabled}
                sx={{ flex: 1, fontSize: '24px', whiteSpace: 'nowrap' }}
                onClick={() => handleStatusBtnClick('in')}
              >
                등원
              </Button>
              <Button
                variant="contained"
                disabled={!isStatusBtnEnabled}
                sx={{ flex: 1, fontSize: '24px', whiteSpace: 'nowrap' }}
                onClick={() => handleStatusBtnClick('out')}
              >
                하원
              </Button>
            </>
          )}
        </Stack>
      </MyArea>
      <StudentsDialog
        title="누구인가요?"
        students={possibleTargetStudentList}
        teachers={possibleTargetTeacherList}
        open={dialogOpen}
        onClose={handleDialogClose}
        onStudentClick={handleStudentClick}
        onTeacherClick={handleTeacherClick}
      />
      <MyAlertDialog store={myAlertStore} />
      <SuccessAlertDialogOfStudent store={successAlertStoreOfStudent} />
      <SuccessAlertDialogOfTeacher store={successAlertStoreOfTeacher} />
      <MenuDialog open={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
});

export default AttForm;
