import { observer } from 'mobx-react-lite';
import Co from 'constants/frontend/shared/Co';
import SuccessAlertStoreOfTeacher from './SuccessAlertStoreOfTeacher';
import SuccessAlertDialog from './SuccessAlertDialog';

type SuccessAlertDialogPropsT = {
  store: SuccessAlertStoreOfTeacher;
};

const SuccessAlertDialogOfTeacher = observer(
  ({ store }: SuccessAlertDialogPropsT) => {
    const handleClose = () => {
      store.hide();
    };

    const { teacher, isOpen, status } = store;

    if (!teacher) return null;

    return (
      <SuccessAlertDialog
        isOpen={isOpen}
        name={teacher.nickname}
        profileUrl={teacher.profileUrl || undefined}
        isTeacher={true}
        statusName={Co.Attendance.attStatusNamesForTeacher.get(status)}
        onClose={handleClose}
      />
    );
  },
);

export default SuccessAlertDialogOfTeacher;
