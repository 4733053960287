import { ServiceConfig } from 'constants/shared/ServiceConfig';
import * as Yup from 'yup';

export const validationSchema = Yup.object({
  academyId: Yup.string()
    .required('출결 전용 원 id를 입력해 주세요.')
    .matches(/^[0-9]*$/, '숫자로 입력해 주세요.'),
  password: Yup.string()
    .required('비밀번호를 입력해 주세요.')
    .length(
      ServiceConfig.Attendance.attPasswordLength,
      `${ServiceConfig.Attendance.attPasswordLength}자리 숫자로 입력해주세요.`,
    )
    .matches(/^[0-9]*$/, '숫자로 입력해 주세요.'),
});

export const initialFormValue = {
  academyId: '',
  password: '',
  submit: undefined,
};
