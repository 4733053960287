import { makeAutoObservable, runInAction } from 'mobx';

class MyAlertStore {
  title = '';
  message = '';
  closeBtn = '';
  confirmBtn = '';
  cancelBtn = '';
  isDestructive = false;
  actionBtns: string[] = [];

  isOpen = false;
  mode: 'alert' | 'confirm' | 'multiple' = 'alert';

  onClose?: (result?: boolean) => void;

  onCloseMultiple?: (result?: number) => void;

  close() {
    this.isOpen = false;
  }

  constructor() {
    makeAutoObservable(this);
  }

  customAlert(title: string, message: string, closeBtn: string): Promise<void> {
    this.title = title;
    this.message = message;
    this.closeBtn = closeBtn;
    this.mode = 'alert';

    return new Promise((resolve) => {
      runInAction(() => {
        this.isOpen = true;
        this.onClose = () => {
          resolve();
        };
      });
    });
  }

  customConfirm(
    title: string,
    message: string,
    confirmBtn: string,
    cancelBtn: string,
    isDestructive = false,
  ): Promise<boolean> {
    this.title = title;
    this.message = message;
    this.confirmBtn = confirmBtn;
    this.cancelBtn = cancelBtn;
    this.isDestructive = isDestructive;
    this.mode = 'confirm';

    return new Promise((resolve) => {
      runInAction(() => {
        this.isOpen = true;
        this.onClose = (result?: boolean) => {
          resolve(!!result);
        };
      });
    });
  }

  multipleChoice(
    title: string,
    message: string,
    actionBtns: { title: string; value: string }[],
    cancelBtn: string,
  ): Promise<string | undefined> {
    this.title = title;
    this.message = message;
    this.actionBtns = actionBtns.map((ab) => ab.title);
    this.cancelBtn = cancelBtn;
    this.mode = 'multiple';

    return new Promise((resolve) => {
      runInAction(() => {
        this.isOpen = true;
        this.onCloseMultiple = (result?: number) => {
          if (result !== undefined) {
            resolve(actionBtns[result].value);
          } else {
            resolve(result);
          }
        };
      });
    });
  }
}

export default MyAlertStore;
