import touchSoundFile from 'assets/sounds/touch.wav';
import deleteSoundFile from 'assets/sounds/delete.wav';
import successSoundFile from 'assets/sounds/success.wav';
import errorSoundFile from 'assets/sounds/error.wav';
import { soundData } from './soundData';
import { base64ToArrayBuffer } from 'utils/base64ToArrayBuffer';

const audioCtx = new AudioContext();
let audioBuffer: AudioBuffer | null = null;
audioCtx.decodeAudioData(base64ToArrayBuffer(soundData)).then((buffer) => {
  audioBuffer = buffer;
});

class SoundStore {
  playCount = 0;

  sounds: Record<'touch' | 'success' | 'error' | 'delete', HTMLAudioElement> = {
    touch: new Audio('data:audio/wav;base64,' + soundData),
    success: new Audio(successSoundFile),
    error: new Audio(errorSoundFile),
    delete: new Audio(deleteSoundFile),
  };

  constructor() {
    Object.values(this.sounds).forEach((sound) => {
      sound.volume = 1;
    });

    this.sounds.touch.addEventListener('ended', () => {
      console.log('ended', this.playCount);
      if (this.playCount > 0) {
        this.playCount--;
        this.playSound('touch');
      }
    });
  }

  playSound = (name: 'touch' | 'success' | 'error' | 'delete') => {
    if (name === 'touch') {
      if (this.sounds.touch.paused) {
        // 즉시 플레이 가능
        console.log('play immediately', this.playCount);
        const source = audioCtx.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioCtx.destination);
        source.start(0);
      } else {
        // 끝나면 연주할 개수 증가
        this.playCount++;
      }
    } else {
      this.sounds[name].play();
    }
  };
}

export default SoundStore;
