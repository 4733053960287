import { makeAutoObservable } from 'mobx';
import { initialFormValue } from './formData';
import { FormikHelpers } from 'formik';
import { SignInRequestT } from 'types/shared/reqAndRes/SignIn';
import { api } from 'utils/api/api';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { TokenObjT } from 'types/shared/core/Token';
import { DO_SIGN_IN } from 'network/shared/user';
import interfaceBridge from 'utils/interfaceBridge';
import Token from 'utils/Token';
import MyAlertStore from 'Alert/MyAlertStore';
import { ErrorCode } from 'constants/shared/ErrorCode';
import { checkLoginState } from 'utils/checkLoginState';

type FormValuesT = typeof initialFormValue;

export default class SignInStore {
  constructor() {
    makeAutoObservable(this);
  }

  myAlertStore = new MyAlertStore();

  init = () => {
    console.log('init');
  };

  submit = async (
    values: FormValuesT,
    helpers: FormikHelpers<FormValuesT>,
  ): Promise<void> => {
    console.log('signin values', values);
    console.log('helpers', helpers);

    const deviceName = interfaceBridge.deviceInfo('deviceName');
    console.log('deviceName', deviceName);

    const email = `att@${values.academyId}`;

    const signInRequest: SignInRequestT = {
      email,
      password: values.password,
      deviceName,
    };

    try {
      const result = await api<SignInRequestT, MyResponse<TokenObjT>>(
        DO_SIGN_IN,
        signInRequest,
      );

      if (result.isSuccess && result.object) {
        console.log('로그인 성공');
        // 로그인 성공

        const payload = Token.getAccessTokenPayload(result.object.accessToken);
        console.log('token payload', payload);

        if (payload === null) {
          // 토큰 확인 안됨
          await this.myAlertStore.customAlert(
            '로그인 실패',
            `응답을 해석할 수 없습니다. 오래된 android 문제일 수 있습니다. 어클에 문의 바랍니다.`,
            '닫기',
          );
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        } else if (payload?.role === 'attendance') {
          // token 저장
          interfaceBridge.saveToken(result.object.userId, result.object);
          interfaceBridge.setData(
            'CURRENT_USER_ID',
            result.object.userId.toString(),
          );

          this.handleSuccess();
        } else {
          const currentRole = payload ? payload.role : 'none';

          // 다른 역할로 로그인
          await this.myAlertStore.customAlert(
            '로그인 실패',
            `출결 전용 원 id로 로그인해야 합니다. (err:${currentRole})`,
            '닫기',
          );
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      } else {
        if (
          result.errorCode ===
          ErrorCode.USER_SIGNIN_INVALID_PASSWORD_OR_NOT_EXISTS
        ) {
          await this.myAlertStore.customAlert(
            '로그인 실패',
            '회원정보가 없거나 잘못된 비밀번호입니다.',
            '닫기',
          );
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        } else {
          await this.myAlertStore.customAlert(
            '로그인 실패',
            `오류가 발생했습니다. (${result.errorCode})`,
            '닫기',
          );
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }
    } catch (err) {
      console.error('SIGN_UP', err);
    }
  };

  handleSuccess = async () => {
    // 이 안에서 일어나는 작업
    // - 원 이름 얻기
    // - 경로 변경
    checkLoginState();
  };
}
