export const Homework = {
  homeworkDifficultyNames: new Map([
    ['easy', '쉬움'],
    ['medium', '할만했음'],
    ['hard', '어려웠음'],
  ]),
  homeworkStatusOfDay: {
    notDoneExists: 1,
    doneExists: 2,
    checkNeededExists: 4,
  },
  homeworkReviewerNames: new Map([
    ['assignerOnly', '내준사람만'],
    ['withManagers', '관리자/운영자도'],
    ['withAssignedTeachers', '학생 담당교사도'],
  ]),
};
