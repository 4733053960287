import { useEffect, useState } from 'react';
import { checkLoginState } from 'utils/checkLoginState';
import { isIOS, isAndroid } from 'react-device-detect';
import { Box, Button, Typography, useTheme } from '@mui/material';
import logoImageDark from 'assets/images/start/logo_dark.png';
import styled from '@emotion/styled';
import IosGuide from './IosGuide';
import AndroidGuide from './AndroidGuide';

let isInited = false;

const AnotherClassLogoImage = styled('img')(
  `
    width: 250px;
    height: auto;
  `,
);

function Check() {
  const [isStandAlone, setIsStandAlone] = useState(true);

  useEffect(() => {
    if (!isInited) {
      isInited = true;

      if (isIOS) {
        if (window.navigator && (window.navigator as any).standalone === true) {
          // alert('ios Running in standalone (PWA) mode');
          checkLoginState();
        } else {
          // alert('ios Running in a browser tab');
          setIsStandAlone(false);
        }
      } else if (isAndroid) {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          // alert('android Running in standalone (PWA) mode');
          checkLoginState();
        } else {
          // alert('android Running in a browser tab');
          setIsStandAlone(false);
        }
      } else {
        // ios/android 아니면 web이라 생각하고 그냥 쓰라함
        // ipad 확인 필요
        checkLoginState();
      }
    }
  }, []);

  const { palette } = useTheme();

  if (isStandAlone) {
    return (
      <Box
        component="main"
        sx={{
          width: '100%',
          backgroundColor: 'background.paper',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            self.location.href = '/signIn';
          }}
        >
          로그인 화면으로 이동
        </Button>
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        width: '100%',
        backgroundColor: 'background.paper',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingTop: '40px',
        paddingBottom: '40px',

        display: 'flex',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          mb: 4,
          px: 4,
        }}
      >
        <AnotherClassLogoImage src={logoImageDark} />
        <Typography variant="h5" color={palette.text.primary} mt={2}>
          어나더클래스 출결 앱
        </Typography>
        <Typography variant="subtitle1" color={palette.text.primary} mt={2}>
          감사합니다! 어나더클래스 출결앱을 모바일 기기 홈화면에 설치해 주세요!
        </Typography>
      </Box>
      {isIOS ? <IosGuide /> : isAndroid ? <AndroidGuide /> : null}{' '}
    </Box>
  );
}
export default Check;
