import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import BackspaceIcon from '@mui/icons-material/Backspace';

type NumberFieldPropsT = {
  chars: string[];
  onBackPressed: () => void;
};

const NumberField = observer(({ chars, onBackPressed }: NumberFieldPropsT) => {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        position: 'relative',
        border: '8px solid #fff',
        borderRadius: '8px',
        borderColor: palette.primary.main,
        padding: '8px',
        mx: '24px',
        textAlign: 'center',
        height: '40px',
        h1: {
          lineHeight: 'inherit',
        },
      }}
    >
      <Typography
        component="div"
        variant="h2"
        color="white"
        sx={{ userSelect: 'none', lineHeight: '40px' }}
      >
        {chars.join('')}
      </Typography>
      <IconButton
        sx={{
          position: 'absolute',
          right: '8px',
          top: 0,
          bottom: 0,
          paddingLeft: '50px',
        }}
        onClick={onBackPressed}
      >
        <BackspaceIcon sx={{ fontSize: '36px' }} color="primary" />
      </IconButton>
    </Box>
  );
});

export default NumberField;
