export const Academy = {
  academyTypeNames: new Map([
    ['academy', '학원'],
    ['schoolHouse', '교습소'],
    ['privateLesson', '개인과외'],
    ['etc', '기타'],
  ]),

  roleNames: new Map([
    ['parent', '학부모'],
    ['teacher', '교사'],
    ['student', '학생'],
  ]),

  subjectNames: new Map([
    ['korean', '국어'],
    ['english', '영어'],
    ['math', '수학'],
    ['science', '과학'],
    ['socialStudies', '사회'],
    ['music', '음악'],
    ['art', '미술'],
    ['pe', '체육'],
    ['coding', '코딩'],
    ['etc', '기타'],
  ]),

  gradeNames: new Map([
    ['undef', '미정'],
    ['age3', '3세'],
    ['age4', '4세'],
    ['age5', '5세'],
    ['age6', '6세'],
    ['age7', '7세'],
    ['ele1', '초1'],
    ['ele2', '초2'],
    ['ele3', '초3'],
    ['ele4', '초4'],
    ['ele5', '초5'],
    ['ele6', '초6'],
    ['mid1', '중1'],
    ['mid2', '중2'],
    ['mid3', '중3'],
    ['high1', '고1'],
    ['high2', '고2'],
    ['high3', '고3'],
    ['nth', 'N수'],
    ['etc', '기타'],
  ]),

  studentStatus: new Map([
    ['enrolled', '재원생'],
    ['notEnrolled', '외부생'],
  ]),

  genderNames: new Map([
    ['male', '남'],
    ['female', '여'],
    ['undef', '미정'],
  ]),

  teacherStatus: new Map([
    ['employed', '재직자'],
    ['resigned', '퇴사자'],
  ]),

  teacherPositions: new Map([
    ['director', '관리자'],
    ['assistantDirector', '운영자'],
    ['regularTeacher', '일반교사'],
  ]),

  clsStatus: new Map([
    ['inOperation', '운영중'],
    ['closed', '운영종료'],
  ]),

  boardReadPermissions: new Map([
    ['director', '관리자만'],
    ['assistantDirector', '관리자,운영자'],
    ['assignedTeacherAndStudent', '배정된 교사,학생'],
    ['allTeacherAndStudent', '모든 교사,학생'],
    ['everyOne', '외부생까지'],
  ]),

  sampleNumberOfStudents: new Map([
    [10, '10명'],
    [50, '50명'],
    [100, '100명'],
    [150, '150명'],
    [200, '200명'],
    [300, '300명'],
    [500, '500명 이상'],
  ]),

  facedIssueCategories: [
    '학원비',
    '출결',
    '소통',
    '숙제',
    '리포트',
    '동기부여',
    '일정관리',
    '컨텐츠',
    '기타',
  ],
};
