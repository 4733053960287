import emptyStateImage from 'assets/images/common/empty_state.png';
import { Box, Typography, useTheme } from '@mui/material';

type EmptyListPropsT = {
  background: 'default' | 'paper';
  message: string;
};

function EmptyState({ message, background }: EmptyListPropsT) {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background:
          background === 'default'
            ? palette.background.default
            : palette.background.paper,
      }}
    >
      <Box sx={{ width: '300px' }}>
        <img
          src={emptyStateImage}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      </Box>
      <Typography variant="body1" align="center" color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
}

EmptyState.defaultProps = {};

export default EmptyState;
