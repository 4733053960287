import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { observer } from 'mobx-react-lite';
import MyAlertStore from './MyAlertStore';

type MyAlertDialogPropsT = {
  store: MyAlertStore;
};

const MyAlertDialog = observer(({ store }: MyAlertDialogPropsT) => {
  const handleClose = (result?: boolean) => {
    store.close();
    store.onClose && store.onClose(result);
  };
  const handleCloseMultiple = (result?: number) => {
    store.close();
    store.onCloseMultiple && store.onCloseMultiple(result);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={store.isOpen}
      onClose={() => {
        store.mode === 'alert' && handleClose();
        // do nothing when confirm && click backdrop
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{store.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {store.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {store.mode === 'alert' && (
          <Button onClick={() => handleClose()} autoFocus>
            {store.closeBtn}
          </Button>
        )}
        {store.mode === 'confirm' && (
          <>
            <Button onClick={() => handleClose(false)}>
              {store.cancelBtn}
            </Button>

            <Button
              onClick={() => handleClose(true)}
              autoFocus
              color={store.isDestructive ? 'error' : 'primary'}
            >
              {store.confirmBtn}
            </Button>
          </>
        )}
        {store.mode === 'multiple' && (
          <>
            <Button onClick={() => handleCloseMultiple()}>
              {store.cancelBtn}
            </Button>

            {store.actionBtns.map((actionBtn, i) => (
              <Button key={i} onClick={() => handleCloseMultiple(i)}>
                {actionBtn}
              </Button>
            ))}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default MyAlertDialog;
