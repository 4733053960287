import './App.css';
import AppRouter from 'AppRouter';
import { SettingsConsumer, SettingsProvider } from 'contexts/settingsContext';
import { ThemeProvider } from '@mui/material';
import { createTheme } from 'theme/index';

function App() {
  return (
    <SettingsProvider>
      <SettingsConsumer>
        {({ settings }) => (
          <ThemeProvider
            theme={createTheme({
              direction: settings.direction,
              responsiveFontSizes: settings.responsiveFontSizes,
              mode: 'dark', // settings.theme,
            })}
          >
            <AppRouter />
          </ThemeProvider>
        )}
      </SettingsConsumer>
    </SettingsProvider>
  );
}

export default App;
