import styled from '@emotion/styled';

const RoundImage = styled('img')`
  margin-top: 16px;
  width: 100%;
  height: auto;
  border-radius: 32px;
`;

export default RoundImage;
