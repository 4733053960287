import AttendanceContainer from './AttendanceContainer';
import AttendanceStore from './AttendanceStore';

const attendanceStore = new AttendanceStore();

function Attendance() {
  return <AttendanceContainer store={attendanceStore} />;
}

export default Attendance;
