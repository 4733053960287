export const Attendance = {
  attStatusNames: new Map([
    ['attended', '수업'],
    ['absent', '결석'],
    ['present', '등원'],
    ['left', '하원'],
    ['custom', '사용자'],
  ]),
  attStatusNamesForTeacher: new Map([
    ['clockIn', '출근'],
    ['clockOut', '퇴근'],
  ]),
  attNotificationMethodNames: new Map([
    ['push', '항상 앱알림'],
    ['pushAndSms', '미가입 학부모에게는 문자'],
    ['sms', '항상 문자메시지'],
  ]),
  attCodeBaseNames: new Map([
    ['parentPhone', '학부모 전화번호 뒷 4자리'],
    ['studentPhone', '학생 전화번호 뒷 4자리'],
  ]),
};
