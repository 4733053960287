/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosResponse } from 'axios';

import Config from 'config/Config';

import Token from 'utils/Token';
import interfaceBridge from 'utils/interfaceBridge';
import { handleDates } from './dateHandler';

import AccessTokenManager from 'utils/AccessTokenManager';
import { API } from 'types/frontend/shared';

const instance = axios.create({
  baseURL: Config.apiBaseUrl,
  timeout: 30000,
  headers: { 'X-Custom-Header': 'foobar' }, // 토큰은 여기 넣지 말고 실시간으로 넣어주자
});

// 여기서의 data변경은 interceptor를 거치는지와 상관없이 항상 적용된다.
const responseBody = <T>(response: AxiosResponse<T>) => {
  if (!response) return response;
  // 받은 내용 중 iso date형식 string을 Date로 변환
  handleDates(response.data);
  return response.data as T;
};

// restapi method별 함수 래핑
const requests = {
  get: <RES>(url: string, params: {}) =>
    instance.get<RES>(url, { params }).then(responseBody),
  post: <RES>(url: string, body: {}) =>
    instance.post<RES>(url, body).then(responseBody),
  put: <RES>(url: string, body: {}) =>
    instance.put<RES>(url, body).then(responseBody),
  delete: <RES>(url: string) => instance.delete<RES>(url).then(responseBody),
};

const api = <REQ, RES>(api: API, request: REQ): Promise<RES> =>
  requests[api.method.toLocaleLowerCase()]<RES>(
    api.path,
    request,
  ) as Promise<RES>;

// 인터셉터 설정하기
instance.interceptors.request.use(
  function (config) {
    const accessToken = Token.getToken('accessToken');
    // console.log('accessToken', accessToken);
    config.headers = config.headers || {};
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log('interceptors response error');
    if (error.config && error.response && error.response.status === 401) {
      const originalRequest = error.config;
      originalRequest._retry = true;

      try {
        console.log('1');
        const newAccessToken = await AccessTokenManager.getNewAccessToken();

        if (AccessTokenManager.accessTokenState === 'ERROR') {
          interfaceBridge.goToSignIn();
          return Promise.reject(error);
        }

        originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
        const res = await axios(originalRequest);
        return res;
      } catch (error: unknown) {
        // access_token 갱신에 실패했으므로
        // 로그인 화면으로 가야함
        return Promise.reject(error);
      }
    } // end of 401

    if (error.config && error.response && error.response.status === 500) {
      // 컴포넌트로 에러를 보내는 것이 맞는건가?
      // 여기에 window.location ... 를 하는 것이 맞는건가?
    }

    console.log('error.config', error.config);
    console.log('error.response', error.response);

    return Promise.reject(error);
  },
);

export { api };

export default instance;
