import { SimpleStudentT } from 'types/shared/core/Student';
import Co from 'constants/frontend/shared/Co';

class StudentHelper {
  static studentDesc = (student: SimpleStudentT) => {
    // console.log(student);
    const schoolName = student.schoolName;
    const gradeName = Co.Academy.gradeNames.get(student.grade || 'undef') || '';
    if (!schoolName || schoolName.length === 0) return gradeName;

    // console.log('student', student.grade);
    // console.log('gradeName', gradeName);

    if (schoolName.slice(-1) === gradeName[0]) {
      return schoolName + gradeName.substring(1);
    }

    return `${schoolName} ${gradeName}`;
  };
}

export default StudentHelper;
