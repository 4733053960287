import { decodeToken } from 'react-jwt';
import interfaceBridge from './interfaceBridge';
import { AccessTokenPayloadT, TokenObjT } from 'types/shared/core/Token';

class Token {
  static getAccessTokenPayload(token: string): AccessTokenPayloadT | null {
    const decoded = decodeToken<AccessTokenPayloadT>(token);
    if (decoded === null) return null;
    return decoded;
  }

  static getToken(kind: 'accessToken' | 'refreshToken') {
    const data = interfaceBridge.getData('CURRENT_USER_ID');
    // console.log(`currentUserId=${data}`);
    const currentUserId = parseInt(data);
    // console.log('currentUserId', currentUserId);
    if (!currentUserId) return '';

    const tokenObj: TokenObjT | null = interfaceBridge.loadToken(currentUserId);

    if (!tokenObj) return '';

    return tokenObj[kind];
  }

  static getCurrentUserId(): number | null {
    const data = interfaceBridge.getData('CURRENT_USER_ID');
    const currentUserId = parseInt(data);
    if (!currentUserId) return null;
    return currentUserId;
  }

  static setCurrentUserId(id: number) {
    interfaceBridge.setData('CURRENT_USER_ID', id.toString());
  }
}

export default Token;
