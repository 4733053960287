import Attendance from 'pages/Attendance/Attendance';
import Check from 'pages/Check/Check';
import SignIn from 'pages/SignIn/SignIn';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Check />} />
        <Route path="/signIn" element={<SignIn />} />
        <Route path="/attendance" element={<Attendance />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
