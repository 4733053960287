import { Box, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';

type KeyPropsT = {
  char: string;
  canClick: boolean;
  onClick: () => void;
  isRound: boolean;
  isWide: boolean;
};

function Key({
  char,
  isRound,
  isWide,
  canClick,
  onClick: handleClick,
}: KeyPropsT) {
  const { palette } = useTheme();
  return (
    <Box
      sx={{
        flex: 1,
        position: 'relative',
        // border: isRound ? '1px solid #f00' : '1px solid #0ff',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onTouchStart={
        !isDesktop
          ? (e) => {
              e.preventDefault();
              handleClick();
            }
          : undefined
      }
      onClick={isDesktop ? handleClick : undefined}
    >
      {isRound ? (
        <Box
          sx={{
            margin: 'auto',
            width: isWide ? undefined : '100%',
            height: isWide ? '100%' : undefined,
            aspectRatio: 1,
            borderRadius: '50%',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: { xs: '40px', sm: '50px' },
            fontWeight: 'bold',
            color: canClick ? '#fff' : palette.action.disabled,
            backgroundColor: palette.neutral![800],
            overflow: 'hidden',
            userSelect: 'none',
          }}
        >
          {char}
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            borderRadius: '100px',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: { xs: '40px', sm: '50px' },
            fontWeight: 'bold',
            color: canClick ? '#fff' : palette.action.disabled,
            backgroundColor: palette.neutral![800],
            overflow: 'hidden',
            userSelect: 'none',
          }}
        >
          {char}
        </Box>
      )}
    </Box>
  );
}

export default Key;
