import { SimpleStudentT } from 'types/shared/core/Student';
import { SimpleTeacherT } from 'types/shared/core/Teacher';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import StudentHelper from 'utils/StudentHelper';
import PersonItem from '../PersonItem';

type PersonDialogPropsT = {
  title: string;
  students: SimpleStudentT[];
  teachers: SimpleTeacherT[];
  open: boolean;
  onClose: () => void;
  onStudentClick: (student: SimpleStudentT) => void;
  onTeacherClick: (teacher: SimpleTeacherT) => void;
};

export default function StudentsDialog({
  title,
  students,
  teachers,
  open,
  onClose: handleClose,
  onStudentClick: handleStudentClick,
  onTeacherClick: handleTeacherClick,
}: PersonDialogPropsT) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        {students.length > 0 ? (
          <Grid container spacing={2} sx={{ minWidth: 228 }}>
            {students.map((student) => (
              <PersonItem
                variant="grid"
                key={student.id}
                avatarSrc={student.profileUrl}
                name={student.name}
                descText={StudentHelper.studentDesc(student)}
                extraText=""
                onClick={() => {
                  handleStudentClick(student);
                }}
              />
            ))}
          </Grid>
        ) : teachers.length > 0 ? (
          <Grid container spacing={2} sx={{ minWidth: 228 }}>
            {teachers.map((teacher) => (
              <PersonItem
                variant="grid"
                key={teacher.teacherId}
                avatarSrc={teacher.profileUrl}
                name={teacher.nickname}
                descText={null}
                extraText=""
                onClick={() => {
                  handleTeacherClick(teacher);
                }}
              />
            ))}
          </Grid>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
}
