import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import Key from './Key';

type NumberPadPropsT = {
  canClick: boolean;
  isRound: boolean;
  onClick: (char: string) => void;
};

const keys = ('123456789*0#'.match(/.{3}/g) || []).map((group) =>
  group.split(''),
);

const px = 4;
const gap = 2;

const NumberField = observer(
  ({ canClick, isRound, onClick: handleClick }: NumberPadPropsT) => {
    const myRef = useRef(null);
    const [isWide, setIsWide] = useState(false);

    useEffect(() => {
      // Ensure the browser supports ResizeObserver
      if ('ResizeObserver' in window) {
        const observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            const widthOfCell = (entry.contentRect.width - gap * 2 * 8) / 3;
            const heightOfCell = (entry.contentRect.height - gap * 3 * 8) / 4;
            setIsWide(widthOfCell > heightOfCell);
            console.log('widthOfCell', widthOfCell);
            console.log('heightOfCell', heightOfCell);
          }
        });

        if (myRef.current) {
          observer.observe(myRef.current);
        }

        // Clean up observer on unmount
        return () => {
          if (myRef.current) {
            observer.unobserve(myRef.current);
          }
        };
      }
    }, []);

    return (
      <Box
        ref={myRef}
        px={px}
        sx={{
          flex: '1 1 0px',
          display: 'flex',
          alignItems: 'stretch',
          flexDirection: 'column',
          gap: `${gap * 8}px`,
          overflow: 'hidden',
        }}
      >
        {keys.map((row, rowIndex) => (
          <Box
            key={rowIndex}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: `${gap * 8}px`,
              overflow: 'hidden',
            }}
          >
            {row.map((col) => (
              <Key
                key={col}
                char={col}
                isRound={isRound}
                isWide={isWide}
                canClick={canClick}
                onClick={() => canClick && handleClick(col)}
              />
            ))}
          </Box>
        ))}
      </Box>
    );
  },
);

export default NumberField;
