import { API } from 'types/frontend/shared';

//
// 학생용
//

// 출결 기록 추가
export const CREATE_ATT_RECORD = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/attendance/record/group`,
});

// 디바이스에서 출결 기록 추가
export const CREATE_ATT_RECORD_FROM_DEVICE = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/attendance/record/device`,
});

/**
 * 출결 기록 불러오기, 학부모와 학생의 월별 출결
 * 학부모, 학생용
 */
export const READ_ATT_RECORD_LIST_OF_USER = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/user?yearMonth=${yearMonth}`,
});

/**
 * 학생관리에서 특정 학생 한명의 월별 출결
 * 일반 교사 가능
 */
export const READ_ATT_RECORD_LIST_OF_STUDENT = (
  academyId: number,
  studentId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/student/${studentId}?yearMonth=${yearMonth}`,
});

/**
 * 클래스관리에서 특정 반이 태그된 월별 출결
 * 일반교사 가능
 */
export const READ_ATT_RECORD_LIST_OF_CLS = (
  academyId: number,
  clsId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/cls/${clsId}?yearMonth=${yearMonth}`,
});

/**
 * 클래스관리에서 특정반의 학생들의 월별 출결
 * 일반교사 가능
 */
export const READ_ATT_RECORD_LIST_OF_STUDENTS_OF_CLS = (
  academyId: number,
  clsId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/studentsOfCls/${clsId}?yearMonth=${yearMonth}`,
});

/**
 * 내가 대상 교사로 지정된 월별 출결
 * teacherId는 필요 없음
 * 일반교사 가능
 */
export const READ_ATT_RECORD_LIST_OWNED_BY_TEACHER = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/ownedByTeacher?yearMonth=${yearMonth}`,
});

/**
 * 오늘의 등하원
 * 자신이 담당하는 학생들의 오늘 등원, 하원, 결석을 조회
 * 일반교사 가능
 */
export const READ_ATT_RECORD_LIST_OF_TODAY = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/ofToday`,
});

/**
 * 월간 출결 요약, 일별 원전체 출결에서 달력에 표기위함
 * 일반교사 호출 불가
 */
export const READ_ATT_RECORD_SUMMARY_OF_ACADEMY = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/monthlySummary?yearMonth=${yearMonth}`,
});

/**
 * 일별 원전체 출결 보기용
 * 일반교사 호출 불가
 */
export const READ_ATT_RECORD_LIST_OF_STUDENTS_OF_DATE = (
  academyId: number,
  date: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/ofDate?date=${date}`,
});

/**
 * 월간 출결 기록
 * 벌크형태
 * 일반교사 호출 불가
 */
export const READ_ATT_RECORD_LIST_OF_STUDENTS_OF_ACADEMY = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/record/ofAcademy?yearMonth=${yearMonth}`,
});

// 출결 기록 삭제
export const DELETE_ATT_RECORD_GROUP = (
  academyId: number,
  groupId: number,
): API => ({
  method: 'DELETE',
  path: `/academy/${academyId}/attendance/record/group/${groupId}`,
});

//
// 교사용
//

// 디바이스에서 근태 기록 추가
export const CREATE_ATT_RECORD_OF_TEACHER_FROM_DEVICE = (
  academyId: number,
): API => ({
  method: 'POST',
  path: `/academy/${academyId}/attendance/recordOfTeacher/device`,
});

// 특정 교사의 근태 기록 불러오기 recordOfTeacher 사용
export const READ_ATT_RECORD_LIST_OF_ME = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/recordOfTeacher/ofMe?yearMonth=${yearMonth}`,
});

// 특정 교사의 근태 기록 불러오기 recordOfTeacher 사용
export const READ_ATT_RECORD_LIST_OF_TEACHER = (
  academyId: number,
  teacherId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/recordOfTeacher/teacher/${teacherId}?yearMonth=${yearMonth}`,
});

// 특정 원의 특정월의 모든 근태기록
export const READ_ATT_RECORD_LIST_OF_TEACHERS_OF_ACADEMY = (
  academyId: number,
  yearMonth: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/attendance/recordOfTeacher/ofAcademy?yearMonth=${yearMonth}`,
});

// 특정 원의 특정일의 모든 근태기록 불러오기 recordOfTeacher 사용
// 이제 사용하지 않음
// export const READ_ATT_RECORD_LIST_OF_TEACHERS_OF_DATE = (
//   academyId: number,
//   date: number,
// ): API => ({
//   method: 'GET',
//   path: `/academy/${academyId}/attendance/recordOfTeacher/ofDate?date=${date}`,
// });

// 근태 기록 삭제
export const DELETE_ATT_RECORD_OF_TEACHER = (
  academyId: number,
  id: number,
): API => ({
  method: 'DELETE',
  path: `/academy/${academyId}/attendance/recordOfTeacher/${id}`,
});
