console.log('helper.token.js loaded');

const saveToken = (messageObj) => {
  if (messageObj.action !== 'saveToken') {
    return { handled: false };
  }

  const tokenInfoStr = localStorage.getItem('ANOTHERCLASS_TOKENS') || '{}';
  let tokens = JSON.parse(tokenInfoStr);
  if (typeof tokens !== 'object') tokens = {};

  console.log('existing tokens', tokens);

  const userId = parseInt(messageObj.userId) || 0;
  const key = userId;

  console.log('userId', userId);

  if (messageObj.tokenObj) {
    tokens[key] = messageObj.tokenObj;
  } else {
    delete tokens[key];
  }

  console.log('new tokens', tokens);

  const newTokenInfoStr = JSON.stringify(tokens);
  localStorage.setItem('ANOTHERCLASS_TOKENS', newTokenInfoStr);

  return { handled: true };
};

const loadToken = (messageObj) => {
  if (messageObj.action !== 'loadToken') {
    return { handled: false };
  }

  const tokenInfoStr = localStorage.getItem('ANOTHERCLASS_TOKENS') || '{}';
  let tokens = JSON.parse(tokenInfoStr);
  if (typeof tokens !== 'object') tokens = {};

  const userId = parseInt(messageObj.userId) || 0;
  const key = userId;
  const returnValue = key in tokens ? JSON.stringify(tokens[key]) : null;

  // console.log('loadToken return value', returnValue);

  return {
    handled: true,
    returnValue,
  };
};

const getAccessToken = () => {
  const currentUserIdObj = getData(
    { action: 'getData', key: 'CURRENT_USER_ID' },
    null,
  );
  const currentUserId = parseInt(currentUserIdObj.returnValue); // 3

  const tokenResult = loadToken(
    { action: 'loadToken', userId: currentUserId },
    null,
  );
  const tokenObj = JSON.parse(tokenResult.returnValue);
  const accessToken = tokenObj.accessToken;
  return accessToken;
};

const setData = (messageObj) => {
  if (messageObj.action !== 'setData') {
    return { handled: false };
  }

  localStorage.setItem(messageObj.key, messageObj.valueStr);

  return { handled: true };
};

const getData = (messageObj) => {
  if (messageObj.action !== 'getData') {
    return { handled: false };
  }

  const data = localStorage.getItem(messageObj.key) || '';

  return {
    handled: true,
    returnValue: data,
  };
};

const getUserIds = (messageObj, sender) => {
  if (messageObj.action !== 'getUserIds') {
    return { handled: false };
  }

  const tokenInfoStr = localStorage.getItem('ANOTHERCLASS_TOKENS') || '{}';
  let tokens = JSON.parse(tokenInfoStr);
  const returnValue =
    typeof tokens === 'object'
      ? JSON.stringify(Object.keys(tokens).map((s) => parseInt(s)))
      : JSON.stringify([]);
  return {
    handled: true,
    returnValue,
  };
};

const deviceInfo = (messageObj, sender) => {
  if (messageObj.action !== 'deviceInfo') {
    return { handled: false };
  }

  // key = 'deviceName' | 'deviceModelName' | 'osVer' | 'appVer' | 'buildNumber'
  let data = localStorage.getItem(messageObj.key) || '';

  if (messageObj.key === 'deviceName' && data === '') {
    const deviceName = 'chromeMockup';
    const myDate = new Date();
    const dateStr =
      myDate.getFullYear() +
      ('0' + (myDate.getMonth() + 1)).slice(-2) +
      ('0' + myDate.getDate()).slice(-2) +
      myDate.getHours() +
      ('0' + myDate.getMinutes()).slice(-2) +
      myDate.getSeconds();
    const rand = 'mock';
    data = `${deviceName}_${dateStr}_${rand}`;

    localStorage.setItem(messageObj.key, data);
  }

  return {
    handled: true,
    returnValue: data,
  };
};

export {
  saveToken,
  loadToken,
  getAccessToken,
  setData,
  getData,
  getUserIds,
  deviceInfo,
};
