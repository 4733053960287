import { observer } from 'mobx-react-lite';
import AttendanceStore, { AttLogT } from '../AttendanceStore';

import MyArea from '../MyArea';
import { Box, ListItem, Stack, Typography, useTheme } from '@mui/material';
import EmptyState from './EmptyState';
import AttStatusChip from './AttStatusChip';
import LogHeader from './LogHeader';

type AttLogPropsT = {
  attendanceStore: AttendanceStore;
};
function Log({ log }: { log: AttLogT }) {
  const { palette } = useTheme();
  return (
    <ListItem divider>
      <Stack direction="row" spacing={2} alignItems="center" flex={1}>
        <AttStatusChip status={log.status} />
        <Typography
          variant="subtitle1"
          color="white"
          sx={{ flex: 1, userSelect: 'none' }}
        >
          {log.name}
        </Typography>
        <Typography
          variant="body2"
          color={palette.text.secondary}
          sx={{ userSelect: 'none' }}
        >
          {log.createdAt.toLocaleString()}
        </Typography>
      </Stack>
    </ListItem>
  );
}
const AttLog = observer(({ attendanceStore }: AttLogPropsT) => {
  console.log('AttLog render');

  const { logs } = attendanceStore;

  const { palette } = useTheme();

  if (!attendanceStore.isWideTablet && attendanceStore.viewMode !== 'log')
    return null;

  return (
    <MyArea width={attendanceStore.isWideTablet ? 'half' : 'full'}>
      <LogHeader
        onBtnClick={
          attendanceStore.isWideTablet
            ? undefined
            : attendanceStore.handleToggleViewMode
        }
      />
      <Box
        flex={1}
        sx={{ background: palette.background.paper, overflowY: 'scroll' }}
      >
        {logs.length ? (
          <Stack>
            {logs.map((log, i) => (
              <Log key={i} log={log} />
            ))}
          </Stack>
        ) : (
          <EmptyState
            background="paper"
            message={'최근 출결 기록이 없습니다.'}
          />
        )}
      </Box>
    </MyArea>
  );
});

export default AttLog;
