import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import interfaceBridge from 'utils/interfaceBridge';

type FormHeaderPropsT = {
  onLeftBtnClick?: () => void;
  onRightBtnClick?: () => void;
};

const academyName = interfaceBridge.getData('ACADEMY_NAME');

function FormHeader({
  onLeftBtnClick: handleLeftBtnClick,
  onRightBtnClick: handleRightBtnClick,
}: FormHeaderPropsT) {
  const { palette } = useTheme();
  return (
    <>
      <Box
        sx={{
          backgroundColor: palette.background.paper,
          height: '44px',
          flex: '0 0 44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h5"
          color="white"
          align="center"
          sx={{ userSelect: 'none', letterSpacing: '-1px' }}
        >
          {academyName}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <IconButton color="primary" onClick={handleLeftBtnClick}>
            <MenuIcon />
          </IconButton>
        </Box>

        {handleRightBtnClick && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Button variant="text" onClick={handleRightBtnClick}>
              로그
            </Button>
          </Box>
        )}
      </Box>
      <Divider />
    </>
  );
}

export default FormHeader;
