import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type SuccessAlertDialogPropsT = {
  isOpen: boolean;
  profileUrl?: string;
  name: string;
  isTeacher: boolean;
  onClose?: () => void;
  statusName?: string;
};

function SuccessAlertDialog({
  isOpen,
  profileUrl,
  name,
  isTeacher,
  statusName,
  onClose,
}: SuccessAlertDialogPropsT) {
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">기록 완료</DialogTitle>
      <DialogContent
        dividers
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Avatar
          src={profileUrl}
          sx={{
            height: 150,
            width: 150,
          }}
        />
        <Typography align="center" variant="h3">
          {name} {isTeacher ? '교사가' : '학생이'}
          <br />
          {statusName} 하였습니다.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>닫기</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessAlertDialog;
