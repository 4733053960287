import SuccessAlertStoreOfStudent from './SuccessAlertStoreOfStudent';
import { observer } from 'mobx-react-lite';
import Co from 'constants/frontend/shared/Co';
import SuccessAlertDialog from './SuccessAlertDialog';

type SuccessAlertDialogPropsT = {
  store: SuccessAlertStoreOfStudent;
};

const SuccessAlertDialogOfStudent = observer(
  ({ store }: SuccessAlertDialogPropsT) => {
    const handleClose = () => {
      store.hide();
    };

    const { student, isOpen, status } = store;

    if (!student) return null;

    return (
      <SuccessAlertDialog
        isOpen={isOpen}
        name={student.name}
        profileUrl={student.profileUrl || undefined}
        isTeacher={false}
        statusName={Co.Attendance.attStatusNames.get(status)}
        onClose={handleClose}
      />
    );
  },
);

export default SuccessAlertDialogOfStudent;
